<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img :src="logo" :alt="appDisplayName" style="max-width: 170px" />
                </div>

                <v-form>
                  <br clear="all" />
                  <center>
                    <h4>{{ $t('message.check_credentials') }}</h4>
                  </center>
                </v-form>
              </v-card-text>

              <v-card-actions style="margin-top: -25px">
                <v-progress-linear indeterminate value="15" color="blue" />
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { loginUrl, userUrl, clientId, clientSecret, getHeader } from '@/config/config';

export default {
  data: () => ({
    loading: true,
    intento_num: 0,
    try_again_max: 3,
  }),

  computed: {
    ...mapState({
      appName: state => state.app.name,
      appDisplayName: state => state.app.displayName,
      appLogoPrimary: state => state.app.images.logoPrimary,
    }),

    logo() {
      return this.$route.path.slice(1, 4) === 'mdk'
        ? 'https://docline-storage.ams3.digitaloceanspaces.com/empresa/yourbrand.png'
        : this.appLogoPrimary;
    },
  },

  mounted() {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
    this.doLogin();
  },
  beforeDestroy() {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.hideBadge();
    }
  },

  methods: {
    ...mapActions('app', ['loadConfigFromUser']),
    ...mapActions('layout', ['showAlert']),

    async doLogin() {
      try {
        if (this.intento_num >= this.try_again_max) {
          localStorage.removeItem('auth_ehealth');
          setTimeout(() => this.$router.push({ path: '/login' }), 5000);
          return this.showError();
        }

        const recaptchaToken = await this.$recaptcha('login');

        const res = await this.$http.post(loginUrl, {
          grant_type: 'nologin',
          client_id: clientId,
          client_secret: clientSecret,
          token: this.$route.params.token,
          email: this.$route.params.email,
          scope: '',
          recaptchaToken: recaptchaToken,
        });
        this.getAuthUser(res.data.access_token, res.data.refresh_token);
      } catch (err) {
        if (err.status === 401 && this.try_again_max > this.try_again_num) {
          this.showError();
          setTimeout(() => this.$router.push({ path: '/login' }), 5000);
        } else {
          this.intento_num++;
          setTimeout(() => this.doLogin(), 1000);
        }

        this.disabledButton = false;
      }
    },

    getAuthUser(accessToken, refreshToken) {
      const authUser = {
        access_token: accessToken,
        refresh_token: refreshToken,
      };

      this.authUserSave(authUser);

      this.$http
        .get(userUrl, { headers: getHeader() })
        .then(response => {
          if (response.body.estado === 1) {
            this.authUserSave(this.authUserMapper(authUser, response.body));
            this.loadConfigFromUser();
            this.$router.push({ name: 'Dashboard' });
          } else {
            if (this.try_again_max > this.try_again_num) {
              this.showError();
              localStorage.removeItem('auth_ehealth');
              setTimeout(() => this.$router.push({ path: '/login' }), 5000);
            } else {
              this.intento_num++;
              setTimeout(() => this.doLogin(), 1000);
            }
          }
          this.disabledButton = false;
        })
        .catch(err => {
          console.log(err);
          this.disabledButton = false;
        });
    },

    authUserMapper(authUser, data) {
      const isPatient = typeof data.patient !== 'undefined' && data.patient.id > 0;
      if (
        !isPatient &&
        typeof data?.empresa?.is_health_personnel !== 'undefined' &&
        data?.empresa?.is_health_personnel === 0
      ) {
        this.load_i18n_message('noHealthPersonnel');
      }

      return {
        ...authUser,
        email: data.user.email,
        name: data.user.name,
        fullName: !isPatient ? data.perfil.fullName : data.user.name + ' ' + data.user.surname,
        id: data.user.id,
        img: !isPatient ? data.perfil.img : '',
        lang: data.user.lang,
        nombreEmpresa: data?.empresa?.nombre || '',
        avatarEmpresa: data?.empresa?.avatar || '',
        logoEmpresa: data?.empresa?.logo || '',
        servicios_activos: !isPatient ? data.perfil.servicios_activos : [],
        rol: data.user.rol,
        roles: data.roles,
        user: data.user,
        trial: !isPatient ? data.perfil.trial : false,
        start_trial: !isPatient ? data.perfil.start_trial : '',
        formReserva: !isPatient ? data.perfil.formReserva : false,
        formPago: !isPatient ? data.perfil.formPago : false,
        formStepToStep: !isPatient ? data.perfil.stepToStep : false,
        collegiateNumber:
          !isPatient && typeof data.perfil.collegiateNumber !== 'undefined' ? data.perfil.collegiateNumber : '',
        permisos: !isPatient ? data.perfil.permisos : '',
        belong_to: !isPatient ? data.perfil.belong_to : 0,
        color: data?.empresa?.color || '',
        colorHexadecimal: data?.empresa?.colorHexadecimal || '',
        lastActivityTime: new Date(),
        permissions: !isPatient ? data.usuario.permissions : null,
        is_health_personnel: data?.empresa?.is_health_personnel || 1,
        is_collective: data?.empresa?.is_collective || 0,
        have_interconsultation: data?.empresa?.interconsultation === 1,
        erecipe: !isPatient && typeof data.perfil.erecipe !== 'undefined' ? data.perfil.erecipe : 0,
        hasSpecialtyToPrescribe:
          !isPatient && typeof data.perfil.hasSpecialtyToPrescribe !== 'undefined'
            ? data.perfil.hasSpecialtyToPrescribe
            : 0,
        company: {
          themeColorPrimary: data?.empresa?.themeColorPrimary || data?.empresa?.colorHexadecimal,
          themeColorSecondary: data?.empresa?.themeColorSecondary || data?.empresa?.colorHexadecimal,
          permissions: data?.empresa?.permissions || null,
          id: data?.empresa?.id || null,
          sharingReceptionist: data?.empresa?.sharingReceptionist,
        },
        isSuperAdmin: !isPatient ? data.usuario.isSuperAdmin : false,
        payment_module: !isPatient ? data.usuario.payment_module : true,
        isPatient: isPatient,
        patient: isPatient ? data.patient : false,
        stripeConfigured:
          !isPatient && typeof data.perfil.stripeConfigured !== 'undefined' ? data.perfil.stripeConfigured : 0,
        environment: data?.environment || [],
      };
    },

    authUserSave(authUser) {
      window.localStorage.setItem('auth_ehealth', JSON.stringify(authUser));
    },

    showError() {
      this.showAlert({
        color: 'warning',
        icon: 'mdi-lock',
        message: this.$t('errors.usr_pw_incorrect'),
      });
    },
  },
};
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background-image: url('/static/faq_bg.png');
}
</style>
